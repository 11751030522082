<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="danger">
      <b-navbar-brand
        >SOCO - Feira {{ new Date().getFullYear() }}</b-navbar-brand
      >
      <b-navbar-nav class="mr-auto">
        <b-nav-form>
          <b-form-input
            id="input-1"
            v-model="form.cpf"
            placeholder="Informe o CPF"
            @change="socio"
            v-mask="'###.###.###-##'"
            required
          ></b-form-input>
        </b-nav-form>
      </b-navbar-nav>
      <b-navbar-brand right>{{ form.nome + " - " + anel }}</b-navbar-brand>
    </b-navbar>

    <b-row
      class="no-print"
      style="background-color: #ccc; padding: 2px 30px; text-align: right"
    >
      <b-col>
        <div v-if="total">
          {{ items.length }} aves inscritas, Total R$ {{ total }}
        </div>
      </b-col>
    </b-row>
    <div style="margin: 20px">
      <b-overlay v-if="aberto" :show="show" rounded="sm">
        <b-card class="no-print">
          <b-row>
            <b-form style="width: 100%">
              <b-form-row>
                <b-col cols md="3" sm="12">
                  <b-form-group label="Espécie">
                    <b-form-select
                      id="input-1"
                      v-model="form.especie"
                      placeholder="Espécie"
                      required
                      :options="optionsEspecies"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols md="3" sm="12">
                  <b-form-group label="Cor">
                    <b-form-input
                      id="input-1"
                      v-model="form.cor"
                      placeholder="Cor"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols md="3" sm="12">
                  <b-form-group label="Sexo">
                    <b-form-select
                      id="input-3"
                      v-model="form.sexo"
                      :options="sexo"
                      required
                      :disabled="disabled"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col cols md="2" sm="12">
                  <b-form-group label="Sigla">
                    <b-form-input
                      id="input-1"
                      v-model="form.anel"
                      placeholder="Sigla"
                      required
                      :disabled="disabled"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols md="2" sm="12">
                  <b-form-group label="Anel" description="123">
                    <b-form-input
                      id="input-2"
                      v-model="form.numero"
                      placeholder="Nro Anel"
                      type="number"
                      required
                      :disabled="disabled"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols md="2" sm="12">
                  <b-form-group label="Ano" description="2021">
                    <b-form-input
                      id="input-2"
                      v-model="form.ano"
                      placeholder="Ano"
                      type="number"
                      required
                      :disabled="disabled"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols md="3" sm="12">
                  <b-form-group label="Preço" :description="liquido">
                    <b-form-input
                      id="input-2"
                      v-model="form.preco"
                      placeholder="Preço"
                      type="number"
                      required
                      :disabled="disabled"
                      :min="100"
                      @change="valorLiquido"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols md="2" sm="12">
                  <b-form-group label="_">
                    <b-button
                      v-if="!disabled"
                      @click="adiciona()"
                      variant="primary"
                      size="sm"
                      >Adicionar</b-button
                    >
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-form>
          </b-row>
        </b-card>
      </b-overlay>
      <b-card>
        <b-overlay :show="show" rounded="sm">
          <b-table responsive striped hover :items="items">
            <template #head(id)=""> Gaiola </template>
            <template #cell(id)="row">
              {{ row.item.gaiola }}
            </template>
            <template #cell(preco)="row">
              {{ row.item.preco + ",00" }}
            </template>
            <template v-if="aberto" #cell(actions)="row">
              <b-button size="sm" @click="remover(row.item)" class="mr-1">
                Remover
              </b-button>
            </template>
          </b-table>
        </b-overlay>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      aberto: false,
      items: [],
      show: false,
      liquido: 0,
      form: {
        anel: "",
        nome: "",
        sexo: "Indefinido",
        ano: null,
        cpf: null,
        especie: null,
        cor: null,
        numero: null,
        preco: null,
        grupo: 0,
      },
      sexo: ["Indefinido", "Macho", "Femea"],
      cpf: "",
      anel: "",
      disabled: true,
      valido: true,
      url: "https://apifeira.socochapeco.com.br/",
      total: 0,
      //url: "https://127.0.0.1:8000/",
      options: [
        { value: 1, text: "Canarios" },
        { value: 2, text: "Exóticos Pequenos" },
        { value: 3, text: "Periquitos" },
        { value: 4, text: "Agapornis" },
        { value: 5, text: "Psitacideos Pequenos" },
        { value: 6, text: "Psitacideos Médios" },
        { value: 7, text: "Psitacideos Grandes" },
        { value: 8, text: "Mansos" },
      ],

      //de acordo com a lista abaixo crie as opções de especies em ordem alfabetica
      //AGAPORNIS, BAVETES, CABEÇA DE AMEIXA, CALAFATES,  CALOPSITAS, CANÁRIO BELGA,  CANÁRIOS DE COR, DEGOLADOS, DIAMANTE DE GOLD, FORPUS, KAKARIKI, KATARINA, MANDARINS, MANONS, MOUSTACHE, NEOPHEMAS, PERIQUITOS, POMBAS DIAMANTES, PSEPHOTUS, RED RUMPED, RING NECKED,  ROSELA, SPARROWS, STAR FINCH
      optionsEspecies: [
        {value: "Agapornis", text: "Agapornis"},
        {value: "Bavetes", text: "Bavetes"},
        {value: "Cabeça de Ameixa", text: "Cabeça de Ameixa"},
        {value: "Calafates", text: "Calafates"},
        {value: "Calopsitas", text: "Calopsitas"},
        {value: "Canário Belga", text: "Canário Belga"},
        {value: "Canários de Cor", text: "Canários de Cor"},
        {value: "Degolados", text: "Degolados"},
        {value: "Diamante de Gold", text: "Diamante de Gold"},
        {value: "Forpus", text: "Forpus"},
        {value: "Kakariki", text: "Kakariki"},
        {value: "Katarina", text: "Katarina"},
        {value: "Mandarins", text: "Mandarins"},
        {value: "Manons", text: "Manons"},
        {value: "Moustache", text: "Moustache"},
        {value: "Neophemas", text: "Neophemas"},
        {value: "Periquitos", text: "Periquitos"},
        {value: "Pombas Diamantes", text: "Pombas Diamantes"},
        {value: "Psephotus", text: "Psephotus"},
        {value: "Red Rumped", text: "Red Rumped"},
        {value: "Ring Necked", text: "Ring Necked"},
        {value: "Rosela", text: "Rosela"},
        {value: "Sparrows", text: "Sparrows"},
        {value: "Star Finch", text: "Star Finch"},
      ]
    };
  },
  methods: {
    remover(item) {
      this.show = true;
      this.axios.get(this.url + "inscricoes/delete/" + item.id).then(() => {
        this.axios.get(this.url + "inscricoes/" + this.form.cpf).then((response) => {
          this.items = response.data;
          this.show = false;
        });
      });
    },
    adiciona() {
      if (this.valida()) {
        this.show = true;
        let self = this;
        this.axios
          .post(this.url + "inscricoes/add", this.form)
          .then(() => {
            this.axios
              .get(this.url + "inscricoes/" + this.form.cpf)
              .then((response) => {
                this.items = response.data;
                this.show = false;
                this.form.ano = null;
                this.form.numero = null;
              });
          })
          .catch(function () {
            self.show = false;
          });
      }
    },
    socio: function () {
      this.show = true;
      let self = this;

      this.form.anel = "";
      this.form.nome = "";
      this.form.sexo = "Macho";
      this.form.ano = null;
      this.form.especie = null;
      this.form.cor = null;
      this.form.numero = null;
      this.form.preco = null;
      this.form.grupo = 0;
      this.anel = "";
      this.items = [];
      this.disabled = true;

      this.axios
        .get("https://socio.socochapeco.com.br/socio/getsocio/" + this.form.cpf)
        .then((response) => {
          this.form.nome = response.data.nome;
          this.form.anel = "EH " + response.data.nro_socio;
          this.anel = "EH " + response.data.nro_socio;
          this.disabled = false;
          this.axios
            .get(this.url + "inscricoes/" + this.form.cpf)
            .then((response) => {
              this.items = response.data;
              for (let i = 0; i < this.items.length; i++) {
                delete this.items[i].grupo;
                //se aberto remove a coluna de ações
                if (!this.aberto) {
                  delete this.items[i].actions;
                }
              }
              this.total = this.items.length * 5 + ",00";
              this.show = false;
            });
        })
        .catch(function () {
          self.show = false;
          self.form.nome = "Sócio não encontrado";
        });
    },
    valida: function () {
      this.valido = true;
      if (
        this.form.anel == null ||
        this.form.nome == null ||
        this.form.sexo == null ||
        this.form.ano == null ||
        this.form.cpf == null ||
        this.form.especie == null ||
        this.form.cor == null ||
        this.form.numero == null ||
        this.form.preco == null
      ) {
        this.valido = false;
        this.makeToast();
      }
      return this.valido;
    },
    makeToast() {
      this.$bvToast.toast("Preencha todos os campos!", {
        variant: "danger",
        title: "Campos Obrigatórios!",
        solid: true,
        autoHideDelay: 3000,
      });
    },
    valorLiquido() {
      this.liquido = "";
      if (this.form.preco > 0) {
        let valor = Math.round((this.form.preco * 0.84) - 5);
        this.liquido = "Previsão valor líquido (venda crédito): R$ " + valor.toFixed(2);
      }
      this.liquido = "";
    },
  },
};
</script>

<style>
@media print {
  .no-print {
    display: none !important;
  }
}
</style>

